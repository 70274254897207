import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { getDeviceData } from "../../services/rtdata-api";

function TimeseriesWidget(props) {
  const [options, setOptions] = useState(defaultOptions(props));
  const [series, setSeries] = useState([]);
  
  useEffect(() => {
    getDeviceData(props.deviceid, props.metric)
      .then(items => {
        setSeries(items);
      })
  }, [props])

  return (
      <div className="widget timeseries-widget">
        <Chart
          options={options}
          series={series}
          type="line"
          width="500"
        />
        <br/>
      </div>
  );
}

function defaultOptions(props) {
  return {
    chart: {
      id: ("timeseries-"+props.deviceid+"-"+props.metric),
      toolbar: {
        show: true
      },
      zoom: {
        type: 'xy',
        autoScaleYaxis: true
      }
    },
    title: {text: ("Metric "+props.metric)},
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      min: function(min) {return min < 0 ? min: 0;}
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      }
    }
  };
}
  
export default TimeseriesWidget;