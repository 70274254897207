import React from 'react';
import TimeseriesWidget from './widget/TimeseriesWidget';

function Dashboard() {
  return <div className="dashboard-container"> 
            <TimeseriesWidget deviceid={"ae175897-6c73-4958-8faa-082d4a81a516"} metric={41} />
            <TimeseriesWidget deviceid={"ae175897-6c73-4958-8faa-082d4a81a516"} metric={53} />
            <TimeseriesWidget deviceid={"ae175897-6c73-4958-8faa-082d4a81a516"} metric={55} />
        </div>;
}

export default Dashboard;