import { DateTime } from 'luxon';

export function getDeviceData(deviceid, metric) {
    var queryString = "?start="+DateTime.utc().startOf('day').toMillis();

    return fetch("https://6ey98faqm3.execute-api.eu-west-1.amazonaws.com/v1/device/"+deviceid+"/"+metric+"/data"+queryString, {crossDomain:true})
    .then(res => res.json())
    .then(
        (result) => {
            var len = result.Count, newData = [], i;

            for ( i=0; i < len; i+=1 ) {
                newData.push( [ parseInt(result.Items[ i ].timestamp.N), parseInt(result.Items[ i ].value.S)] );   
            }

            return ([{name:(deviceid+"."+metric),data:newData}]);
        }
    )
}